import React from 'react';
import { Heading } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledSubtitle = styled(Heading)`
  font-family: Poppins;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.93px;
`;

const CardSubtitle = ({ children, mx, my, color, fontSize }) => {
  return (
    <StyledSubtitle mx={mx} my={my} color={color} fontSize={fontSize || '24px'}>
      {children}
    </StyledSubtitle>
  );
};

export default CardSubtitle;
