import { API, STORAGE_KEYS } from '../resources/constants';
import axios from 'axios';

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'x-access-token': `${process.env.API_TOKEN}`
  };
};

/* Retrieves available locations */
export const getLocationsService = async zipCode => {
  return await axios.get(
    `${API.service}/${API.scheduling}/locations/?zipCode=${zipCode}`,
    {
      headers: getHeaders()
    }
  );
};

/* Retrieves available providers */
export const getProvidersService = async locationId => {
  return await axios.get(
    `${API.service}/${API.scheduling}/providers/?locationId=${locationId}`,
    {
      headers: getHeaders()
    }
  );
};

/* Retrieves available appointments slots */
export const getAppointmentsSlotsService = async data => {
  return await axios.post(
    `${API.service}/${API.scheduling}/appointments-slots`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Register patient in scheduling service provider */
export const createPatientService = async data => {
  return await axios.post(
    `${API.service}/${API.scheduling}/${localStorage.getItem(
      STORAGE_KEYS.ID
    )}/patient`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Schedules an appointment with a provider */
export const createAppointmentService = async data => {
  return await axios.post(
    `${API.service}/${API.scheduling}/${localStorage.getItem(
      STORAGE_KEYS.ID
    )}/appointment`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Registers a new patient */
export const patientsService = async data => {
  return await axios.post(`${API.service}/${API.patients}`, data, {
    headers: getHeaders()
  });
};

/* Starts patient quiz */
export const startQuizService = async () => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${process.env.QUIZ_CONTENTFUL_ID}`,
    '',
    {
      headers: getHeaders()
    }
  );
};

/* Stores sections responses */
export const storeSectionQuizService = async (sectionId, data) => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/sections/${sectionId}`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Completes the quiz sending the last section response */
export const completeQuizService = async data => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/complete`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Retrieves the analysis for the specified quiz */
export const getQuizAnalysisService = async () => {
  return await axios.get(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/analysis`,
    {
      headers: getHeaders()
    }
  );
};

/* Generates the report for the specified quiz */
export const getReportService = async () => {
  return await axios.get(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/report`,
    {
      headers: getHeaders()
    }
  );
};

/* Share report */
export const shareReportService = async data => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/report`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Subscribe patient to newsletter */
export const suscribePatientService = async data => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(
      STORAGE_KEYS.ID
    )}/subscribe`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Confirm patient payment */
export const confirmPatientPaymentService = async data => {
  return await axios.post(
    `${API.service}/${API.billing}/${localStorage.getItem(
      STORAGE_KEYS.ID
    )}/payment`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Request more information about a particular package */
export const RequestInfoPackageService = async (data, packageId) => {
  return await axios.post(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.packages
    }/${packageId}/info`,
    data,
    {
      headers: getHeaders()
    }
  );
};

/* Get access code for diagnosis toolkit */
export const getAccessCode = async data => {
  return await axios.get(
    `${API.service}/${API.patients}/${localStorage.getItem(STORAGE_KEYS.ID)}/${
      API.quizzes
    }/${localStorage.getItem(STORAGE_KEYS.QUIZ_ID)}/code`,
    {
      headers: getHeaders()
    }
  );
};
